<template>
  <div>
    <b-modal size="md" id="modal-verifylink" centered hide-footer hide-header>
      <div class="card-body">
        <h4 class="card-title text-center">Almost There!</h4>
        <div class="card-text text-center">
          {{ urlMessage }}
        </div>
        <div class="card-text mt-2 text-center">
          <button
            @click="redirect"
            class="btn btn-signup w-100 text-center py-2 px-5 rounded"
          >
            <b>Click Here to Complete your payment</b>
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="open-monthly-essential-plan-agreement"
      centered
      hide-footer
      hide-header
      size="lg"
    >
      <div class="row juris-modal">
        <div class="col">
          <div class="agreement-logo">
            <img src="../../assets/nexmind/nexLaw_Blue_Logo.png" alt="Nexlaw" />
          </div>

          <h4 class="mt-4">
            <strong>NexLaw Monthly Subscription Agreement</strong>
          </h4>

          <p class="mt-3 mb-4">
            The NexLaw Subscription Agreement (this “Agreement”) comprises the
            Terms incorporated by reference in Section 1 and the subscription to
            NexLaw SaaS Services as outlined herein.
          </p>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>1. Subscription Agreement</strong>
              <p class="mt-2 mb-4">
                NexLaw grants the subscriber a non-exclusive, non-transferable,
                limited license to access and use the NexLaw SaaS services
                (“Services”) in accordance with the NexLaw Terms of Service
                (“TOS”) and the pricing outlined in the Price Schedule (“Price
                Schedule”) as of the date the subscriber accepts this Agreement
                (“Effective Date”). The TOS together with the Price Schedule
                (together, the “Terms”) are incorporated herein by reference and
                are deemed part of this Agreement. Subscriber may view and print
                the Terms at: [NexLaw Terms](<a
                  class="text-primary td-underline"
                  @click="goToTerms"
                  >https://nexlaw.ai/terms-of-use/</a
                >) and [NexLaw Subscribe](<a
                  class="text-primary td-underline"
                  @click="goToPrivacy"
                  >https://nexlaw.ai/subscribe/</a
                >).
              </p>
            </li>
          </ul>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>2. Offers and Term</strong>
              <p class="mt-2 mb-2">
                Subscriber commits to license the Services for 1 Subscriber
                User(s) for an initial term of one (1) month and to pay the
                monthly subscription price of $119.00, in equal monthly payments
                during the term (“Subscription Offer”).
              </p>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="col">
                      Following the expiry of the initial term, the Services
                      will automatically renew for further renewal terms unless
                      either party provides written notice of non-renewal to the
                      other party at least three (3) days before the end of the
                      term. The initial term plus any applicable renewal terms
                      shall constitute the term of this Agreement. Subscriber
                      can cancel this Agreement at any time. If Subscriber
                      cancels during the initial term or any subsequent renewal
                      term, Subscriber will retain access to the Services until
                      the expiration of the then-current term. NexLaw will not
                      provide reimbursement for any time remaining in the term.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>3. Payments and Disclaimers</strong>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="col">
                      Payments are due and must be made according to the
                      Subscription Offer. For Subscription Offers, during the
                      term, prices for Services will not be increased from those
                      posted on the Price Schedule at the time the Services
                      became effective or were renewed, except where prices are
                      identified as temporary. All prices are subject to change
                      at the beginning of any renewal term.
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col">
                      Use of the Services under this Agreement is available to
                      Subscriber only in support of Subscriber’s practice of law
                      or other ordinary professional use. NexLaw makes no
                      representations as to accuracy, completeness, correctness,
                      suitability, or validity of any information provided
                      through the Services and will not be liable for any errors
                      or omissions or any losses, injuries, or damages arising
                      from use of the Services.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>4. Miscellaneous</strong>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="col">
                      This Agreement may be terminated by Subscriber on fifteen
                      (15) days’ prior written notice to NexLaw in the event (a)
                      NexLaw discontinues providing access to a significant
                      portion of the Services that Subscriber has used regularly
                      in the ordinary course of business during the term, or (b)
                      due to a material breach by NexLaw that remains uncured
                      for more than thirty (30) days after NexLaw receives
                      written notice from Subscriber identifying a specific
                      breach. To be effective, notice of termination pursuant to
                      (a) or (b) above must be given within ninety (90) days of
                      the event giving rise to the termination right.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <p class="mt-3 mb-2">
            All notices must be in writing by email will be considered delivered
            on the email transmission date, the date received at the mailing
            address, the date shown on the return receipt, or the date on the
            courier confirmation of delivery, whichever is first. For
            subscription cancellations, email
            <a class="text-primary td-underline" @click="goToSupportEmail"
              >support@nexlaw.ai</a
            >. For all other legal notices, email
            <a class="text-primary td-underline" @click="goToNexLawEmail"
              >info@nexlaw.ai</a
            >. Notices from the subscriber to NexLaw may be emailed to the email
            address identified on the subscriber’s account:
            <a class="text-primary td-underline" @click="goToSupportEmail"
              >support@nexlaw.ai</a
            >.
          </p>
          <p class="mt-3 mb-2">
            This Agreement is the entire agreement concerning its subject matter
            and supersedes any prior or concurrent communications.
          </p>
          <p class="mt-3 mb-4">
            NexLaw will not accept any changes, corrections, or additions to
            this Agreement unless such changes are expressly accepted by NexLaw
            in writing.
          </p>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="open-yearly-essential-plan-agreement"
      centered
      hide-footer
      hide-header
      size="lg"
    >
      <div class="row juris-modal">
        <div class="col">
          <div class="agreement-logo">
            <img src="../../assets/nexmind/nexLaw_Blue_Logo.png" alt="Nexlaw" />
          </div>

          <h4 class="mt-4">
            <strong>NexLaw Yearly Subscription Agreement</strong>
          </h4>

          <p class="mt-3 mb-4">
            The NexLaw Subscription Agreement (this “Agreement”) comprises the
            Terms incorporated by reference in Section 1 and the subscription to
            NexLaw SaaS Services as outlined herein.
          </p>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>1. Subscription Agreement</strong>
              <p class="mt-2 mb-4">
                NexLaw grants the subscriber a non-exclusive, non-transferable,
                limited license to access and use the NexLaw SaaS services
                (“Services”) in accordance with the NexLaw Terms of Service
                (“TOS”) and the pricing outlined in the Price Schedule (“Price
                Schedule”) as of the date the subscriber accepts this Agreement
                (“Effective Date”). The TOS together with the Price Schedule
                (together, the “Terms”) are incorporated herein by reference and
                are deemed part of this Agreement. Subscriber may view and print
                the Terms at: [NexLaw Terms](<a
                  class="text-primary td-underline"
                  @click="goToTerms"
                  >https://nexlaw.ai/terms-of-use/</a
                >) and [NexLaw Subscribe](<a
                  class="text-primary td-underline"
                  @click="goToPrivacy"
                  >https://nexlaw.ai/subscribe/</a
                >).
              </p>
            </li>
          </ul>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>2. Offers and Term</strong>
              <p class="mt-2 mb-2">
                Subscriber commits to license the Services for 1 Subscriber
                User(s) for an initial term of one (1) year and to pay the
                monthly subscription price of $89.00, in equal monthly payments
                during the term (“Subscription Offer”).
              </p>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="col">
                      Following the expiry of the initial term, the Services
                      will automatically renew for further renewal terms unless
                      either party provides written notice of non-renewal to the
                      other party at least three (3) days before the end of the
                      term. The initial term plus any applicable renewal terms
                      shall constitute the term of this Agreement. Subscriber
                      can cancel this Agreement at any time. If Subscriber
                      cancels during the initial term or any subsequent renewal
                      term, Subscriber will retain access to the Services until
                      the expiration of the then-current term. NexLaw will not
                      provide reimbursement for any time remaining in the term.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>3. Payments and Disclaimers</strong>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="col">
                      Payments are due and must be made according to the
                      Subscription Offer. For Subscription Offers, during the
                      term, prices for Services will not be increased from those
                      posted on the Price Schedule at the time the Services
                      became effective or were renewed, except where prices are
                      identified as temporary. All prices are subject to change
                      at the beginning of any renewal term.
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col">
                      Use of the Services under this Agreement is available to
                      Subscriber only in support of Subscriber’s practice of law
                      or other ordinary professional use. NexLaw makes no
                      representations as to accuracy, completeness, correctness,
                      suitability, or validity of any information provided
                      through the Services and will not be liable for any errors
                      or omissions or any losses, injuries, or damages arising
                      from use of the Services.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>4. Miscellaneous</strong>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="col">
                      This Agreement may be terminated by Subscriber on fifteen
                      (15) days’ prior written notice to NexLaw in the event (a)
                      NexLaw discontinues providing access to a significant
                      portion of the Services that Subscriber has used regularly
                      in the ordinary course of business during the term, or (b)
                      due to a material breach by NexLaw that remains uncured
                      for more than thirty (30) days after NexLaw receives
                      written notice from Subscriber identifying a specific
                      breach. To be effective, notice of termination pursuant to
                      (a) or (b) above must be given within ninety (90) days of
                      the event giving rise to the termination right.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <p class="mt-3 mb-2">
            All notices must be in writing by email will be considered delivered
            on the email transmission date, the date received at the mailing
            address, the date shown on the return receipt, or the date on the
            courier confirmation of delivery, whichever is first. For
            subscription cancellations, email
            <a class="text-primary td-underline" @click="goToSupportEmail"
              >support@nexlaw.ai</a
            >. For all other legal notices, email
            <a class="text-primary td-underline" @click="goToNexLawEmail"
              >info@nexlaw.ai</a
            >. Notices from the subscriber to NexLaw may be emailed to the email
            address identified on the subscriber’s account:
            <a class="text-primary td-underline" @click="goToSupportEmail"
              >support@nexlaw.ai</a
            >.
          </p>
          <p class="mt-3 mb-2">
            This Agreement is the entire agreement concerning its subject matter
            and supersedes any prior or concurrent communications.
          </p>
          <p class="mt-3 mb-4">
            NexLaw will not accept any changes, corrections, or additions to
            this Agreement unless such changes are expressly accepted by NexLaw
            in writing.
          </p>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="open-monthly-small-firm-plan-agreement"
      centered
      hide-footer
      hide-header
      size="lg"
    >
      <div class="row juris-modal">
        <div class="col">
          <div class="agreement-logo">
            <img src="../../assets/nexmind/nexLaw_Blue_Logo.png" alt="Nexlaw" />
          </div>

          <h4 class="mt-4">
            <strong>NexLaw Monthly Subscription Agreement</strong>
          </h4>

          <p class="mt-3 mb-4">
            The NexLaw Subscription Agreement (this “Agreement”) comprises the
            Terms incorporated by reference in Section 1 and the subscription to
            NexLaw SaaS Services as outlined herein.
          </p>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>1. Subscription Agreement</strong>
              <p class="mt-2 mb-4">
                NexLaw grants the subscriber a non-exclusive, non-transferable,
                limited license to access and use the NexLaw SaaS services
                (“Services”) in accordance with the NexLaw Terms of Service
                (“TOS”) and the pricing outlined in the Price Schedule (“Price
                Schedule”) as of the date the subscriber accepts this Agreement
                (“Effective Date”). The TOS together with the Price Schedule
                (together, the “Terms”) are incorporated herein by reference and
                are deemed part of this Agreement. Subscriber may view and print
                the Terms at: [NexLaw Terms](<a
                  class="text-primary td-underline"
                  @click="goToTerms"
                  >https://nexlaw.ai/terms-of-use/</a
                >) and [NexLaw Subscribe](<a
                  class="text-primary td-underline"
                  @click="goToPrivacy"
                  >https://nexlaw.ai/subscribe/</a
                >).
              </p>
            </li>
          </ul>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>2. Offers and Term</strong>
              <p class="mt-2 mb-2">
                Subscriber commits to license the Services for 1 Subscriber
                User(s) for an initial term of one (1) month and to pay the
                monthly subscription price of $199.00, in equal monthly payments
                during the term (“Subscription Offer”).
              </p>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="col">
                      Following the expiry of the initial term, the Services
                      will automatically renew for further renewal terms unless
                      either party provides written notice of non-renewal to the
                      other party at least three (3) days before the end of the
                      term. The initial term plus any applicable renewal terms
                      shall constitute the term of this Agreement. Subscriber
                      can cancel this Agreement at any time. If Subscriber
                      cancels during the initial term or any subsequent renewal
                      term, Subscriber will retain access to the Services until
                      the expiration of the then-current term. NexLaw will not
                      provide reimbursement for any time remaining in the term.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>3. Payments and Disclaimers</strong>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="col">
                      Payments are due and must be made according to the
                      Subscription Offer. For Subscription Offers, during the
                      term, prices for Services will not be increased from those
                      posted on the Price Schedule at the time the Services
                      became effective or were renewed, except where prices are
                      identified as temporary. All prices are subject to change
                      at the beginning of any renewal term.
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col">
                      Use of the Services under this Agreement is available to
                      Subscriber only in support of Subscriber’s practice of law
                      or other ordinary professional use. NexLaw makes no
                      representations as to accuracy, completeness, correctness,
                      suitability, or validity of any information provided
                      through the Services and will not be liable for any errors
                      or omissions or any losses, injuries, or damages arising
                      from use of the Services.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>4. Miscellaneous</strong>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="col">
                      This Agreement may be terminated by Subscriber on fifteen
                      (15) days’ prior written notice to NexLaw in the event (a)
                      NexLaw discontinues providing access to a significant
                      portion of the Services that Subscriber has used regularly
                      in the ordinary course of business during the term, or (b)
                      due to a material breach by NexLaw that remains uncured
                      for more than thirty (30) days after NexLaw receives
                      written notice from Subscriber identifying a specific
                      breach. To be effective, notice of termination pursuant to
                      (a) or (b) above must be given within ninety (90) days of
                      the event giving rise to the termination right.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <p class="mt-3 mb-2">
            All notices must be in writing by email will be considered delivered
            on the email transmission date, the date received at the mailing
            address, the date shown on the return receipt, or the date on the
            courier confirmation of delivery, whichever is first. For
            subscription cancellations, email
            <a class="text-primary td-underline" @click="goToSupportEmail"
              >support@nexlaw.ai</a
            >. For all other legal notices, email
            <a class="text-primary td-underline" @click="goToNexLawEmail"
              >info@nexlaw.ai</a
            >. Notices from the subscriber to NexLaw may be emailed to the email
            address identified on the subscriber’s account:
            <a class="text-primary td-underline" @click="goToSupportEmail"
              >support@nexlaw.ai</a
            >.
          </p>
          <p class="mt-3 mb-2">
            This Agreement is the entire agreement concerning its subject matter
            and supersedes any prior or concurrent communications.
          </p>
          <p class="mt-3 mb-4">
            NexLaw will not accept any changes, corrections, or additions to
            this Agreement unless such changes are expressly accepted by NexLaw
            in writing.
          </p>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="open-yearly-small-firm-plan-agreement"
      centered
      hide-footer
      hide-header
      size="lg"
    >
      <div class="row juris-modal">
        <div class="col">
          <div class="agreement-logo">
            <img src="../../assets/nexmind/nexLaw_Blue_Logo.png" alt="Nexlaw" />
          </div>

          <h4 class="mt-4">
            <strong>NexLaw Yearly Subscription Agreement</strong>
          </h4>

          <p class="mt-3 mb-4">
            The NexLaw Subscription Agreement (this “Agreement”) comprises the
            Terms incorporated by reference in Section 1 and the subscription to
            NexLaw SaaS Services as outlined herein.
          </p>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>1. Subscription Agreement</strong>
              <p class="mt-2 mb-4">
                NexLaw grants the subscriber a non-exclusive, non-transferable,
                limited license to access and use the NexLaw SaaS services
                (“Services”) in accordance with the NexLaw Terms of Service
                (“TOS”) and the pricing outlined in the Price Schedule (“Price
                Schedule”) as of the date the subscriber accepts this Agreement
                (“Effective Date”). The TOS together with the Price Schedule
                (together, the “Terms”) are incorporated herein by reference and
                are deemed part of this Agreement. Subscriber may view and print
                the Terms at: [NexLaw Terms](<a
                  class="text-primary td-underline"
                  @click="goToTerms"
                  >https://nexlaw.ai/terms-of-use/</a
                >) and [NexLaw Subscribe](<a
                  class="text-primary td-underline"
                  @click="goToPrivacy"
                  >https://nexlaw.ai/subscribe/</a
                >).
              </p>
            </li>
          </ul>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>2. Offers and Term</strong>
              <p class="mt-2 mb-2">
                Subscriber commits to license the Services for 1 Subscriber
                User(s) for an initial term of one (1) year and to pay the
                monthly subscription price of $169.00, in equal monthly payments
                during the term (“Subscription Offer”).
              </p>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="col">
                      Following the expiry of the initial term, the Services
                      will automatically renew for further renewal terms unless
                      either party provides written notice of non-renewal to the
                      other party at least three (3) days before the end of the
                      term. The initial term plus any applicable renewal terms
                      shall constitute the term of this Agreement. Subscriber
                      can cancel this Agreement at any time. If Subscriber
                      cancels during the initial term or any subsequent renewal
                      term, Subscriber will retain access to the Services until
                      the expiration of the then-current term. NexLaw will not
                      provide reimbursement for any time remaining in the term.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>3. Payments and Disclaimers</strong>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="col">
                      Payments are due and must be made according to the
                      Subscription Offer. For Subscription Offers, during the
                      term, prices for Services will not be increased from those
                      posted on the Price Schedule at the time the Services
                      became effective or were renewed, except where prices are
                      identified as temporary. All prices are subject to change
                      at the beginning of any renewal term.
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col">
                      Use of the Services under this Agreement is available to
                      Subscriber only in support of Subscriber’s practice of law
                      or other ordinary professional use. NexLaw makes no
                      representations as to accuracy, completeness, correctness,
                      suitability, or validity of any information provided
                      through the Services and will not be liable for any errors
                      or omissions or any losses, injuries, or damages arising
                      from use of the Services.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>4. Miscellaneous</strong>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="col">
                      This Agreement may be terminated by Subscriber on fifteen
                      (15) days’ prior written notice to NexLaw in the event (a)
                      NexLaw discontinues providing access to a significant
                      portion of the Services that Subscriber has used regularly
                      in the ordinary course of business during the term, or (b)
                      due to a material breach by NexLaw that remains uncured
                      for more than thirty (30) days after NexLaw receives
                      written notice from Subscriber identifying a specific
                      breach. To be effective, notice of termination pursuant to
                      (a) or (b) above must be given within ninety (90) days of
                      the event giving rise to the termination right.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <p class="mt-3 mb-2">
            All notices must be in writing by email will be considered delivered
            on the email transmission date, the date received at the mailing
            address, the date shown on the return receipt, or the date on the
            courier confirmation of delivery, whichever is first. For
            subscription cancellations, email
            <a class="text-primary td-underline" @click="goToSupportEmail"
              >support@nexlaw.ai</a
            >. For all other legal notices, email
            <a class="text-primary td-underline" @click="goToNexLawEmail"
              >info@nexlaw.ai</a
            >. Notices from the subscriber to NexLaw may be emailed to the email
            address identified on the subscriber’s account:
            <a class="text-primary td-underline" @click="goToSupportEmail"
              >support@nexlaw.ai</a
            >.
          </p>
          <p class="mt-3 mb-2">
            This Agreement is the entire agreement concerning its subject matter
            and supersedes any prior or concurrent communications.
          </p>
          <p class="mt-3 mb-4">
            NexLaw will not accept any changes, corrections, or additions to
            this Agreement unless such changes are expressly accepted by NexLaw
            in writing.
          </p>
        </div>
      </div>
    </b-modal>

    <div class="page-wrapper-lg h-100 d-flex align-items-center">
      <div class="row">
        <div class="col-2"></div>
        <div class="nexcard col-8">
          <h4 class="fw-bold text-center mb-3">
            Subscribe to NexLaw's {{ category | capitalize }}
            {{ plan_name | capitalize }} Plan
          </h4>

          <p class="text-center">
            The subscription fee will be billed on a {{ category }} basis. Prior
            to completing your payment, we kindly request that you carefully
            review and accept our {{ category }} subscription agreement.
          </p>

          <div class="text-center">
            <b-form-checkbox
              class="text-center"
              v-if="category == 'yearly' && plan_id == 500"
              id="checkbox-2"
              v-model="status"
              name="checkbox-2"
              value="accepted"
              unchecked-value="not_accepted"
            >
              <small class=""
                >I agree to the
                <a
                  @click="openPlanAgreement('yearly', 500)"
                  class="text-primary td-underline"
                  >NexLaw's Yearly Essential Plan Subscription Agreement</a
                >
              </small>
            </b-form-checkbox>

            <b-form-checkbox
              v-if="category == 'monthly' && plan_id == 500"
              id="checkbox-2"
              v-model="status"
              name="checkbox-2"
              value="accepted"
              unchecked-value="not_accepted"
            >
              <small class=""
                >I agree to the
                <a
                  @click="openPlanAgreement('monthly', 500)"
                  class="text-primary td-underline"
                  >NexLaw's Monthly Essential Plan Subscription Agreement</a
                >
              </small>
            </b-form-checkbox>

            <b-form-checkbox
              v-if="category == 'monthly' && plan_id == 510"
              id="checkbox-2"
              v-model="status"
              name="checkbox-2"
              value="accepted"
              unchecked-value="not_accepted"
            >
              <small class=""
                >I agree to the
                <a
                  @click="openPlanAgreement('monthly', 510)"
                  class="text-primary td-underline"
                  >NexLaw's Monthly Complete Plan Subscription Agreement</a
                >
              </small>
            </b-form-checkbox>

            <b-form-checkbox
              class="text-center"
              v-if="category == 'yearly' && plan_id == 510"
              id="checkbox-2"
              v-model="status"
              name="checkbox-2"
              value="accepted"
              unchecked-value="not_accepted"
            >
              <small class=""
                >I agree to the
                <a
                  @click="openPlanAgreement('yearly', 510)"
                  class="text-primary td-underline"
                  >NexLaw's Yearly Complete Plan Subscription Agreement</a
                >
              </small>
            </b-form-checkbox>
          </div>

          <form class="py-4">
            <p class="text-blue fw-bold">Credit or Debit Card</p>
            <div class="mb-3">
              <div
                id="card-element"
                class="cardno__input input-blue"
                style="color: black; font-size: 16px"
              ></div>
            </div>
          </form>

          <h4 class="fw-bold mb-3">Order Summary</h4>
          <hr />

          <div class="d-flex mt-2 align-items-center flex-column flex-sm-row">
            <h5 class="me-auto align-items-center">
              NexLaw {{ category | capitalize }}
              {{ plan_name | capitalize }} Subscription
            </h5>

            <div class="d-flex me-auto ms-sm-auto me-sm-0 align-items-center">
              <h5 class="me-3 fw-bold">${{ price }}/Month</h5>
              <!-- <h5 v-if="category == 'monthly'" class="me-3 fw-bold">
                ${{ price }}/Month
              </h5>
              <h5 v-if="category == 'yearly'" class="ms-3 fw-bold">
                ${{ price * 12 }}/Year
              </h5> -->
            </div>
          </div>

          <hr />

          <button v-if="processing" disabled class="btn btn-signup mt-3 w-100">
            <span class="text-center"
              >Processing
              <b-spinner label="Spinning" class="spinner mx-2"></b-spinner
            ></span>
          </button>
          <button v-else @click="update" class="btn btn-signup mt-3 w-100">
            Pay Now
          </button>
        </div>
        <div class="col-3"></div>
      </div>
    </div>
  </div>
</template>

<script src="https://js.stripe.com/v3/"></script>

<script>
/* eslint-disable */
import Plan from "@/store/Plan.js";

var stripe = "";
if (location.href.includes("www.platform.nexlaw.ai")) {
  stripe = Stripe(
    "pk_live_51Is063AYOu0yvcMNoEPlJDAt9kLyqwPUlB80OA0plRQXfhBuepCf0kHsKmLc2JrGc7v4al4X2Y98GegjyjrLz0w300FwaKv0Qo"
  );
} else {
  stripe = Stripe(
    "pk_test_51OZ36EA6VB5l7p337MMykTsm4GbmDTIv4xcaW1VdiNZgn1Gt8HgnR8S9N8HUNAUMsEaeBpzof0XGyXxluQqeuSsp00NfhBjNuK"
  );
}

const elements = stripe.elements();
const cardElement = elements.create("card");

const cardHolderName = document.getElementById("card-holder-name");
const cardButton = document.getElementById("card-button");

export default {
  data() {
    return {
      plan_name: this.$route.params.plan_name,
      category: this.$route.params.period,
      plan_id: this.$route.params.plan_id,
      plan_price_id: this.$route.params.plan_price_id,
      price: this.$route.params.price,
      status: "not_accepted",
      id: "",
      processing: false,
      redirectUrl: "",
      urlMessage: "",
    };
  },

  methods: {
    goToPrivacy() {
      window.open("https://www.nexlaw.ai/privacy-policy/");
    },

    goToTerms() {
      window.open("https://www.nexlaw.ai/terms-of-use/");
    },

    goToNexLawEmail() {
      window.open("mailto:info@nexlaw.ai");
    },

    goToSupportEmail() {
      window.open("mailto:info@nexlaw.ai");
    },

    openPlanAgreement(category, plan_id) {
      if (category === "monthly" && plan_id === 500) {
        this.$bvModal.show("open-monthly-essential-plan-agreement");
      } else if (category === "yearly" && plan_id === 500) {
        this.$bvModal.show("open-yearly-essential-plan-agreement");
      } else if (category === "monthly" && plan_id === 510) {
        this.$bvModal.show("open-monthly-small-firm-plan-agreement");
      } else if (category === "yearly" && plan_id === 510) {
        this.$bvModal.show("open-yearly-small-firm-plan-agreement");
      }
    },

    redirect() {
      this.$bvModal.hide("modal-verifylink");
      window.open(this.redirectUrl, "_self");
      // localStorage.removeItem("accessToken");
      // localStorage.removeItem("userInfo");
    },

    async update(e) {
      if (this.status == "accepted") {
        this.processing = true;
        e.preventDefault();
        const { paymentMethod, error } = await stripe.createPaymentMethod(
          "card",
          cardElement,
          {
            billing_details: {
              name: JSON.parse(localStorage.userInfo).email,
            },
          }
        );

        if (error) {
          this.processing = false;
          console.error(error);
          this.$toast.error(error.message);
        } else {
          this.id = paymentMethod.id;
          this.UpgradeData();

          //Submit the form
          form.submit();
        }
      } else {
        if (this.category == "monthly") {
          this.$toast.error(
            "Kindly agree to NexLaw's Monthly Subscription Agreement"
          );
        } else if (this.category == "yearly") {
          this.$toast.error(
            "Kindly agree to NexLaw's Yearly Subscription Agreement"
          );
        } else {
          this.$toast.error("Kindly agree to NexLaw's Subscription Agreement");
        }
      }
    },

    //Set Plan API withoud Plan ID n Price
    UpgradeData() {
      var obj = {};

      obj = {
        paymentMethodId: this.id,
        plan_id: this.plan_id,
        plan_price_id: this.plan_price_id,
      };

      // console.log(obj); return;

      Plan.subscripe_to_plan(obj)
        .then((response) => {
          // this.$toast.info(response.data.message);

          if (response.data.data.url) {
            this.urlMessage = response.data.message;

            this.redirectUrl = response.data.data.url;

            this.$bvModal.show("modal-verifylink");
          } else {
            if (response.data.message == "Successful") {
              this.$toast.success("Payment Successful!");
              this.$toast.success("Welcome to NexLaw!");
              this.$router.push("/login");
            }
          }

          this.processing = false;

          // setTimeout(this.$router.push({ path: "/login" }), 10000);
        })
        .catch((err) => {
          this.processing = false;
          console.error(err);
        });
    },

    //Set Plan API with Plan ID n Price
    // UpgradeData() {
    //   var obj = {};

    //   obj = {
    //     paymentMethodId: this.id,
    //     plan: this.plan_id.toString(),
    //     plan_price_id: this.plan_price_id.toString(),
    //   };

    //   console.log(obj);

    //   Plan.set_client_plans(obj)
    //     .then((response) => {
    //       // this.$toast.info(response.data.message);

    //       if (response.data.data.url) {
    //         this.urlMessage = response.data.message;

    //         this.redirectUrl = response.data.data.url;

    //         this.$bvModal.show("modal-verifylink");
    //       } else {
    //         if (response.data.message == "Successful") {
    //           this.$toast.success("Payment Successful!");
    //           this.$toast.success("Welcome to NexLaw!");
    //           this.$router.push("/login");
    //         }
    //       }

    //       this.processing = false;

    //       // setTimeout(this.$router.push({ path: "/login" }), 10000);
    //     })
    //     .catch((err) => {
    //       this.processing = false;
    //       console.error(err);
    //     });
    // },
  },

  mounted() {
    cardElement.mount("#card-element");
  },

  created() {
    this.category = this.$route.params.period;
    this.plan_id = this.$route.params.plan_id;
    this.plan_price_id = this.$route.params.plan_price_id;
    this.price = this.$route.params.price;

    this.plan_name =
      this.plan_id == 500
      ? "Essential"
      : this.plan_id == 510
      ? "Complete" : "N/A";
  },
};
</script>

<style lang="css" scoped>
.juris-modal {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 10px;
  padding-right: 10px;
}

.agreement-logo {
  margin: 1rem 0rem 0rem;
  display: flex;
  justify-content: start;
  align-items: center;
  user-select: none;
}

.agreement-logo img {
  height: 80px;
  object-fit: contain;
}

.input-blue {
  border-radius: 8px !important;
  border: 2px solid #094890 !important;
  height: 45px !important;
}
.cardno__input.input-blue {
  padding-top: 12px;
  padding-left: 10px;
}

.btn-signup {
  background-color: #094890;
  color: #fff;
  border-radius: 12px;
  transition: 0.4s ease-out;
}

.btn-signup:hover {
  background-color: #073b76;
  color: #fff;
  border-radius: 12px;
  transition: 0.4s ease-out;
}

.hr-dblue {
  height: 1px;
  background-color: #094890;
  border: none;
  opacity: 1;
}

.spinner-border {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-vertical-align: -0.15em;
  --bs-spinner-border-width: 0.2em;
}
</style>
